<template lang="pug">
.wrapper.q-pb-xl
    .col-12(v-if="!isActivAccessibility")
        // carousel-skeleton.q-mt-xl(v-if="!advertismentItemsTop.length")
        carousel-desktop(
            :slides="advertismentItemsTop")
    .col-12.carousel(v-if="discount?.length")
        product-slider-skeleton-desktop(
            title
            :height="350"
            v-if="!discount?.length")
        product-slider-desktop(
            v-else-if="discount?.length"
            label="Предложения по лучшим ценам"
            :slides="discount"
            :manufacture="true")
    .col-12.q-mt-lg.carousel(v-show="advertismentItemsBottom !== null" v-if="!isActivAccessibility")
        router-link.text-primary.font-24.text-weight-medium.carousel__title.decoration-no(
            to="marketing-list"
            title="Акции" itemprop="text") Акции
        carousel-skeleton.q-mt-md(v-if="!advertismentItemsBottom?.length")
        carousel-desktop.q-mt-md(
            v-show="advertismentItemsBottom?.length"
            :slides="advertismentItemsBottom")
    .font-24.text-primary.text-weight-medium.q-my-xl
        | Почему выбирают онлайн-аптеку {{ link }}?
    .row.relative-position.q-mt-md.full-block.justify-between
        .col-4.column.relative-position.full-block__content.reason(v-for="(item, key) in reasons")
            .row.justify-center(style="min-height: 130px; max-height='156px'")
                img(
                    :src="`interface/about/${item.icon_top}.svg`"
                )
                .col-9.column
                    .font-20.text-primary.text-weight-medium.text-center.q-mx-auto.title-top(:class="{ 'mw-1' : (key===2) }")
                        | {{ item.title_top }}
                    div.text-center
                        span.font-16.q-mt-md.text-center.block
                            | {{ item.subtitle_top}}
                        a(href="tel:+78002005400" v-if="key == 2" rel="nofollow").text-primary.hover-color-positive.font-16.q-mr-xs.link.white-space-nowrap
                            |  8-800-200-5-400
                        span.font-16.q-mt-md(v-if="key == 2")
                            | (С 8:00 по 22:00)
.col-12.additional.q-pr-lg.q-py-md.q-my-lg#additional(v-if='season?.products.length' )
    .wrapper.q-pb-xl
        p.text-primary.font-20.text-weight-medium.q-pl-lg
             | Сезонные товары
        productSliderDesktop(
            :slides="season.products"
            :standartPreview="false"
            prodPage
            )
.wrapper.q-pb-xl
    articles-desktop(
        v-if="articles"
        :articles="articles"
    )
    h1.font-24.text-weight-medium.q-mt-lg.text-primary(v-if="!isActivAccessibility")
        | eTabl.ru – онлайн-аптека
    .q-pb-lg.q-pt-sm.font-16(v-if="!isActivAccessibility")
        span
            | На официальном сайте eTabl.ru можно найти интересующий препарат, подобрать аналог, изучить медицинскую информацию и получить консультацию специалиста.
        span.text-weight-medium.q-ml-xs
            | Мы своевременно пополняем ассортимент:
    .row.justify-between(v-if="!isActivAccessibility")
        router-link.column.items-center.text-center.link.text-primary(
            v-for="item in ceo" 
            :to="city ? `/${city.slug}/${item.link}` : `/${item.link}`")
            q-img(
                :src="item.img"
                no-spinner
                height="66px"
                width="66px"
                fit="contain"
            ).q-mb-md
            .font-16(v-html="item.title")
    .col-12.carousel.q-mt-lg(v-if="history?.length && userAuthorized")
        product-slider-desktop(
            label="Вы недавно просматривали"
            :slides="history"
            :manufacture="true")
    Alphabet

</template>

<script>
import {computed, onMounted, ref} from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import BaseBtn from 'components/Base/BaseBtn.vue';
import CarouselDesktop from 'components/Carousel/CarouselDesktop.vue';
import CarouselSkeleton from'components/Carousel/CarouselSkeleton.vue';
import ProductSliderDesktop from 'components/ProductSlider/ProductSliderDesktop.vue';
import ProductSliderSkeletonDesktop from 'components/ProductSlider/ProductSliderSkeletonDesktop.vue';
import ArticlesDesktop from 'components/Articles/ArticlesDesktop.vue';
import FastLinks from 'components/FastLinks/FastLinks.vue';
import Alphabet from 'components/Alphabet/Alphabet.vue';
export default {
    name: 'HomeDesktop',
    components: {
        Alphabet,
        BaseBtn,
        ProductSliderDesktop,
        CarouselDesktop,
        CarouselSkeleton,
        ProductSliderSkeletonDesktop,
        ArticlesDesktop,
        FastLinks,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const discount = computed(() => store.getters['advertisement/DISCOUNT']);
        const history = computed(() => store.getters['advertisement/HISTORY']);
        const advertisment = computed(() => store.getters['advertisement/ADVERTISMENT']);
        const articles = computed(() => store.getters['advertisement/ARTICLES']);
        const season = computed(() => store.getters['advertisement/SEASONS']);

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        // авторизован ли пользователь
        const userAuthorized = computed(() => store.getters['user/USER_AUTHORIZED']);

        const advertismentItemsTop = computed(() => {
            return (advertisment.value?.result || [])
                .filter((item) => {
                    return item.viewLocation === 'top'
                        && item.image;
                });
        });
        const advertismentItemsBottom = computed(() => {
            const result = (advertisment.value?.result || [])
                .filter((item) => {
                    return item.viewLocation === 'bottom'
                        && item.image;
                });
            return result.length ? result : [];
        });

        const userCityId = computed(() => store.getters['user/USER_CITY_ID']);
        const isActivAccessibility = computed(() => store.getters['styles/isActiv']);

        const goTo = (path) => {
            router.push(`/marketing/${path}`);
        };

        const ceo = [
            {
                title: 'лекарственных средств,<br>витаминов и БАД',
                img: 'ceo/1.svg',
                link: 'catalog/lekarstva-i-bady=000000001',
            }, {
                title: 'профессиональной<br>косметики',
                img: 'ceo/2.svg',
                link: 'catalog/krasota-i-u%D1%85od=000000199',
            }, {
                title: 'товаров для малышей, будущих<br>мам и кормящих женщин',
                img: 'ceo/3.svg',
                link: 'catalog/gigiena=000000314',
            }, {
                title: 'медицинских портативных приборов,<br>предметов ухода за больными',
                img: 'ceo/4.svg',
                link: 'catalog/pribory-medicinskie=000000346',
            }, 
        ];
        const link = `${process.env.APP_SERVICE_HOSTNAME}`;
        const reasons = ref([
            {
                icon_top: 'reasons-1',
                title_top: 'Широкий ассортимент 50 000 товаров',
                subtitle_top: 'В наших аптеках и под заказ на сайте',
                icon_bottom: '1_bot',
                title_bottom: 'Программа лояльности скидки до 20%',
                subtitle_bottom: 'Гибкая и выгодная программа для клиентов',
            }, {
                icon_top: 'reasons-2',
                title_top: 'Гарантия качества',
                subtitle_top: 'Оригинальные лекарства и товары надлежащего качества',
                icon_bottom: '2_bot',
                title_bottom: 'Бронирование заказов круглосуточно',
                subtitle_bottom: 'Бронируйте заказ на сайте или по телефону ',
            }, {
                icon_top: 'reasons-3',
                title_top: 'Доставка курьером на дом и в офис',
                subtitle_top: ' Заказывайте на сайте или по телефону ',
                icon_bottom: '3_bot',
                title_bottom: 'Консультация квалифицированного специалиста',
                subtitle_bottom: 'Качественный сервис',
            },
        ]);
        return {
            history,
            discount,
            season,
            advertisment,
            advertismentItemsTop,
            advertismentItemsBottom,
            goTo,
            link,
            reasons,
            userAuthorized,
            ceo,
            userCityId,
            articles,
            isActivAccessibility,
            city,
        };
    },

};
</script>
<style lang="scss" scoped>
    :deep(.q-page.mobile) {
        min-height: 100px !important;
    }

    .carousel {
        &__title {
            @media screen and (max-width: $breakpoint-mobile) {
                font: {
                    size: 16px;
                    weight: 18px;
                }
            }
        }
    }
    .carousel__title {
        @media  (max-width: 1280px) {
            font-size: 1rem;
        }
    }

    .ceo {
        &__text {
            width: 747px;

            @media screen and (max-width: 1280px) {
                width: 485px;
            }
        }
        &__img {
            height: 298px;
            margin-right: 8px;

            @media screen and (max-width: 1440px) {
                height: 278px;
            }
            @media screen and (max-width: 1280px) {
                height: 240px;
                margin-top: 48px;
            }
        }
    }
    .title-top{
      max-width: 234px;
    }
    .mw-1{
      max-width: 227px;
    }
    .additional {
        background: #E2F0E8;
        border-radius: 16px;
        overflow: hidden;
        height: 184px;
    }

</style>
